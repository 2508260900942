<template>
    <div class="map-app d-flex flex-column">
        <device-selector @onSearch="search"></device-selector>
        <gmaps-Map @mounted="mapReady" ref="map" :options="mapOptions">
            <gmaps-Marker  v-for="(item,i) in markers" :key="i" 
                :position="item.position" 
                :options="item" 
                :icon="(item.icon==''||item.icon==null)?'':getIcon(item.icon)" 
                @click="showInfo(item)"
                :visible="item.visible"
                >
            </gmaps-Marker>
            <gmaps-polyline :path="markers"  />
            <gmaps-info-window ref="info" :options="winInfo">
            <div>
                <span style="background: yellow">{{winInfo.deviceName}}</span> <br />
                <span>{{winInfo.uniqueid}}</span> <br />
                <span>{{getDateString(winInfo.creado)}}</span>
            </div>
            </gmaps-info-window>
        </gmaps-Map>
        <position-table :items="markers"></position-table>
    </div>
</template>

<script>
import { gmapsMap, gmapsMarker, gmapsInfoWindow, gmapsPolyline } from 'x5-gmaps';
import { mapActions } from 'vuex';

export default {metaInfo: { title: 'Vigilante History' },
    components:{
        gmapsMap,
        gmapsMarker,
        gmapsInfoWindow,
        gmapsPolyline,
        //mapDevices: ()=> import('./sections/vigilante/mapDevices.vue'),
        deviceSelector: () => import('./sections/History/DeviceSelector.vue'),
        positionTable:() => import('./sections/vigilante/PositionTable.vue'),
    },
    data: ()=>({
        markers:[]
        ,map:null
        ,winInfo:{
            disableAutoPan:true,
            pixelOffset:{height:-35},
            position:{lat:0,lng:0},
            m:{}
        }
        ,interval: null
        ,iconSize:null
        ,mapOptions: {
        center: { lat: 25.6629001, lng: -100.2853851 },
        zoom: 12
      }
    }),
    methods:{
        ...mapActions(['getLatestPositions','showSnackbar']),
        getDateString(date){
            if(date == undefined)
                return "";
            var dformat = this.$func.getFullDateString(date);

            return dformat;
        },
        mapReady(map){
            this.map = map;
//            console.log(google);
            //this.initialLoad(map,true);
        },
        showInfo(marker){
            this.winInfo = marker;
            this.$refs.info.open();
        },
        getIcon(icon){
            
            return{
                url:icon,
                scaledSize: this.iconSize
            }
        },
        search(data){
            //console.log(data);
            this.markers = data.data;
            this.updateBounds();
        }
        ,updateBounds(){
            /*global google*/ 
            var bounds = new google.maps.LatLngBounds();
            this.iconSize = new google.maps.Size(50, 50);
            
            this.markers.forEach((marker) => {
                bounds.extend(new google.maps.LatLng(marker.position.lat, marker.position.lng));
            });
            this.map.fitBounds(bounds);
        }
    }
}
</script>

<style>
.map-app{
    height: 100%;
}
</style>